import { configureStore, combineReducers } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import storageSession from "redux-persist/lib/storage/session";
import persistReducer from "redux-persist/lib/persistReducer";
import authReducer from "../Redux/authSlice";
import subscriptionReducer from "../Redux/subscriptionSlice";
import orgReducer from "../Redux/orgDetailsSlice";
import loaderReducer from "../Redux/loaderSlice";
import pre_SignReducer from "../Redux/preSignSlice";
import filterOptionReducer from "../Redux/filterOptions";
import mainReportSlice from "../Redux/mainReportSlice";
import saveFilterReducer from "../Redux/savedFilterRedux";
import opportunitiesReducer from "../Redux/opportunitiesDataSlice";
import missingproductReducer from "../Redux/missingDataSlice";
import secondaryconsumerReducer from "../Redux/secondaryConsumer";
import ghostconsumerReducer from "../Redux/ghostConsumer";
import navigationHistoryReducer from "../Redux/navigationHistorySlice";
import selectedFilterSlice from "../Redux/selectedFilterSlice";
import { opportunitiesAnalysisSlice } from "../rtk-query/features/dashboard/opportunities/opportunitiesAnalysisSlice";
import { missingProductsAnalysisSlice } from "../rtk-query/features/dashboard/missingProducts/missingProductsAnalysisSlice";
import { ghostConsumerAnalysisSlice } from "../rtk-query/features/dashboard/ghostConsumer/ghostConsumerAnalysisSlice";
import { secondaryConsumerAnalysisSlice } from "../rtk-query/features/dashboard/secondaryConsumer/secondaryConsumerAnalysisSlice";
import { financialHealthScoreAnalysisSlice } from "../rtk-query/features/dashboard/financialHealthScore/financialHealthScoreAnalysisSlice";
import { topCompetitorsAnalysisSlice } from "../rtk-query/features/dashboard/topCompetitors/topCompetitorsAnalysisSlice";
import { fileAnalyticsAnalysisSlice } from "../rtk-query/features/dashboard/fileAnalytics/fileAnalyticsAnalysisSlice";
import { missedRevenueAnalysisSlice } from "../rtk-query/features/dashboard/missedRevenue/missedRevenueAnalysisSlice";
import { bondScoreAnalysisSlice } from "../rtk-query/features/dashboard/bondScore/bondScoreAnalysisSlice";
import { efficiencyRatioAnalysisSlice } from "../rtk-query/features/dashboard/efficiencyRatio/efficiencyRatioAnalysisSlice";
import { topMerchantsAnalysisSlice } from "../rtk-query/features/dashboard/topMerchants/topMerchantsAnalysisSlice";
import { consumerDemographicsAnalysisSlice } from "../rtk-query/features/dashboard/consumerDemographics/consumerDemographicsAnalysisSlice";
import { topPersonaAnalysisSlice } from "../rtk-query/features/dashboard/topPersona/topPersonaAnalysisSlice";
import { newUserGrowthAnalysisSlice } from "../rtk-query/features/dashboard/newUserGrowth/newUserGrowthAnalysisSlice";
import { trendsAnalysisSlice } from "../rtk-query/features/dashboard/trends/trendsAnalysisSlice";
import { rtkQueryErrorLogger } from "../rtk-query/features/rtkQueryErrorLogger";
import { registrationSlice } from "../rtk-query/features/auth/register/registrationSlice";

const rootPersistConfig = {
  key: "root",
  storage: storage,
  blacklist: ["authReducer"],
};

const authPersistConfig = {
  key: "authReducer",
  storage: storageSession,
  blacklist: ["somethingTemporary"],
};

const rootReducer = combineReducers({
  authReducer: persistReducer(authPersistConfig, authReducer),
  orgReducer: orgReducer,
  subscriptionReducer: subscriptionReducer,
  loaderReducer: loaderReducer,
  pre_SignReducer: pre_SignReducer,
  hidReducer: mainReportSlice,
  filterOptionReducer: filterOptionReducer,
  saveFilterReducer: saveFilterReducer,
  opportunitiesReducer: opportunitiesReducer,
  missingproductReducer: missingproductReducer,
  secondaryconsumerReducer: secondaryconsumerReducer,
  ghostconsumerReducer: ghostconsumerReducer,
  navigationHistoryReducer: navigationHistoryReducer,
  selectedFilterSlice: selectedFilterSlice,
  [opportunitiesAnalysisSlice.reducerPath]: opportunitiesAnalysisSlice.reducer,
  [missingProductsAnalysisSlice.reducerPath]:
    missingProductsAnalysisSlice.reducer,
  [ghostConsumerAnalysisSlice.reducerPath]: ghostConsumerAnalysisSlice.reducer,
  [secondaryConsumerAnalysisSlice.reducerPath]:
    secondaryConsumerAnalysisSlice.reducer,
  [financialHealthScoreAnalysisSlice.reducerPath]:
    financialHealthScoreAnalysisSlice.reducer,
  [topCompetitorsAnalysisSlice.reducerPath]:
    topCompetitorsAnalysisSlice.reducer,
  [fileAnalyticsAnalysisSlice.reducerPath]: fileAnalyticsAnalysisSlice.reducer,
  [secondaryConsumerAnalysisSlice.reducerPath]:
    secondaryConsumerAnalysisSlice.reducer,
  [financialHealthScoreAnalysisSlice.reducerPath]:
    financialHealthScoreAnalysisSlice.reducer,
  [missedRevenueAnalysisSlice.reducerPath]: missedRevenueAnalysisSlice.reducer,
  [bondScoreAnalysisSlice.reducerPath]: bondScoreAnalysisSlice.reducer,
  [efficiencyRatioAnalysisSlice.reducerPath]:
    efficiencyRatioAnalysisSlice.reducer,
  [secondaryConsumerAnalysisSlice.reducerPath]:
    secondaryConsumerAnalysisSlice.reducer,
  [financialHealthScoreAnalysisSlice.reducerPath]:
    financialHealthScoreAnalysisSlice.reducer,
  [topCompetitorsAnalysisSlice.reducerPath]:
    topCompetitorsAnalysisSlice.reducer,
  [fileAnalyticsAnalysisSlice.reducerPath]: fileAnalyticsAnalysisSlice.reducer,
  [topMerchantsAnalysisSlice.reducerPath]: topMerchantsAnalysisSlice.reducer,
  [consumerDemographicsAnalysisSlice.reducerPath]:
    consumerDemographicsAnalysisSlice.reducer,
  [topPersonaAnalysisSlice.reducerPath]: topPersonaAnalysisSlice.reducer,
  [newUserGrowthAnalysisSlice.reducerPath]: newUserGrowthAnalysisSlice.reducer,
  [trendsAnalysisSlice.reducerPath]: trendsAnalysisSlice.reducer,
  [registrationSlice.reducerPath]: registrationSlice.reducer,
});

const persistedReducer = persistReducer(rootPersistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableStateInvariant: false,
    }).concat([
      opportunitiesAnalysisSlice.middleware,
      missingProductsAnalysisSlice.middleware,
      ghostConsumerAnalysisSlice.middleware,
      secondaryConsumerAnalysisSlice.middleware,
      financialHealthScoreAnalysisSlice.middleware,
      topCompetitorsAnalysisSlice.middleware,
      fileAnalyticsAnalysisSlice.middleware,
      missedRevenueAnalysisSlice.middleware,
      bondScoreAnalysisSlice.middleware,
      efficiencyRatioAnalysisSlice.middleware,
      topMerchantsAnalysisSlice.middleware,
      consumerDemographicsAnalysisSlice.middleware,
      topPersonaAnalysisSlice.middleware,
      newUserGrowthAnalysisSlice.middleware,
      trendsAnalysisSlice.middleware,
      registrationSlice.middleware,
      rtkQueryErrorLogger,
    ]),
});
export default store;
