import { isRejected } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { toastResponse } from "../../helper components/Toast/Toast";

const errorCodes = new Map();
errorCodes.set(400, "BAD_REQUEST");
errorCodes.set(401, "UNAUTHORIZED");
errorCodes.set(403, "FORBIDDEN");
errorCodes.set(404, "NOT_FOUND");
const getErrorMessage = (code) => errorCodes.get(code);

export const rtkQueryErrorLogger = (api) => (next) => (action) => {
  // isRejectedWithValue Or isRejected
  if (isRejected(action)) {
    if (
      Number.isInteger(action.payload?.status) &&
      action.payload?.status !== 200
    ) {

      // TODO : Need to change the status code comparion with the global constants
      if (
        action.payload?.status !== 400 &&
        action.payload?.status !== 401 &&
        action.payload?.status !== 404
      ) {
        toast.error(
          toastResponse(
            action.payload?.data?.status,
            getErrorMessage(action.payload?.status),
          ),
        );
      }
    }
  }

  return next(action);
};
