import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  orguid: sessionStorage.getItem("selectedOrgId") ?? null,
  orgName: sessionStorage.getItem("selectedOrgName") ?? null,
  orgShortName: sessionStorage.getItem("selectedOrgName")
    ? (sessionStorage.getItem("selectedOrgName")?.split("-"))[0]
    : "",
  orgKey: null,
  healthAnalysisID: null,
  selectedFI: {},
  refreshedFI: false,
  registeredFiList: [],
  allFiList: [],
};

export const orgDetailsSlice = createSlice({
  name: "orgDetails",
  initialState,
  reducers: {
    setOrgId: (state, action) => {
      state.orguid = action.payload;
      sessionStorage.setItem("selectedOrgId", action.payload);
    },
    setOrgName: (state, action) => {
      state.orgName = action.payload;
    },
    setOrgShortName: (state, action) => {
      state.orgShortName = action.payload;
    },
    setOrgKey: (state, action) => {
      state.orgKey = action.payload;
    },
    setSelectedFI: (state, action) => {
      state.selectedFI = action.payload;
    },
    setRefreshedFI: (state, action) => {
      state.refreshedFI = action.payload;
    },
    setRegisteredFiList: (state, action) => {
      state.registeredFiList = action.payload;
    },
    setAllFiList: (state, action) => {
      state.allFiList = action.payload;
    },
    setHealthAnalysisID: (state, action) => {
      state.healthAnalysisID = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setOrgId,
  setOrgName,
  setOrgShortName,
  setOrgKey,
  setSelectedFI,
  setRefreshedFI,
  setRegisteredFiList,
  setAllFiList,
  setHealthAnalysisID,
} = orgDetailsSlice.actions;

export default orgDetailsSlice.reducer;
