import React, { useState, useEffect } from "react";
import "./ConsumersListManagementPage.scss";
import "../themeTable.scss";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dropdown } from "primereact/dropdown";
// import { MultiSelect } from "primereact/multiselect";
import { useDispatch, useSelector } from "react-redux";
import { setLoaderFalse, setLoaderTrue } from "../../../../Redux/loaderSlice";
import searchLogo from "../../../../assets/images/Search.png";
// import filterinactive from "../../../../assets/images/Filter.png";
// import filteractive from "../../../../assets/images/Filter_selected.png";
import { useNavigate, useParams } from "react-router";
import exportimages from "../../../../assets/images/exportImages";
import GetFromServer from "../../../../utils/GetFromServer";
import {
  getFormattedDateType1,
} from "../campaignConstant";
import { toast } from "react-toastify";
import { toastResponse } from "../../../../helper components/Toast/Toast";
// import { Modal } from "react-bootstrap";
// import { DeleteToServer } from "../../../../utils/DeleteToServer";
// import { setSelectedFilterDetails } from "../../../../Redux/savedFilterRedux";

export const ConsumersDetailsPage = (props) => {
  const { id } = useParams();
  const [filteredConsumerList, setFilteredConsumerList] = useState(null);
  const dispatch = useDispatch();
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  // Use useLocation hook to access location object
  const location = useLocation();
  const { name } = location.state;
  // const [rowData, setRowData] = useState(null);
  // const [modal, setmodal] = useState(false);
  // const [selectedRowData, setSelectedRowData] = useState(null);
  const navigate = useNavigate();
  const orgReducer = useSelector((state) => state.orgReducer);
  const orgClientId = orgReducer?.selectedFI?.orgClientId;
  const [filters, setFilter] = useState({
    global: {
      value: null,
      matchMode: FilterMatchMode.CONTAINS,
    },
  });
  //FILTER STATE FOR GLOBAL FILTER

  useEffect(() => {
    /**
     *  FOR GETING DATA ONLOAD
     */
    fetchConsumerListData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchConsumerListData = async () => {
    (async () => {
      dispatch(setLoaderTrue());

      // TODO: change 234567 to dynamic value
      try {
        const response = await GetFromServer(
          `/bank/customer/${orgClientId}/${id}`,
          {},
          {},
          {},
        );
        if (response.status === 200) {
          setFilteredConsumerList(response.data.data?.customers);
        }
      } catch (error) {
      } finally {
        dispatch(setLoaderFalse());
      }
    })();
  };
  //   const uploadeStatusFilterTemplate = (options) => {
  //     return `${options.label} (${options.count})`;
  //   };
  // const filterCampaignDataFunc = (e) => {
  //   //FILTER ON STATUS IN TABLE HEADER
  //   setSelectedUploadStatus(e.value);
  //   if (e.value.length === 0) {
  //     // setFilteredEmailCampaigns(emailCampaigns);
  //   } else {
  //     let options = e.value.map((item) => item.label);
  //     // let newCampaign = emailCampaigns.filter((campaign) => {
  //     //   let index = options.find(
  //     //     (element) => element === campaign?.processStatus,
  //     //   );
  //     //   if (index !== undefined) {
  //     //     return true;
  //     //   } else {
  //     //     return false;
  //     //   }
  //     // });
  //     // setFilteredEmailCampaigns(newCampaign);
  //   }
  // };
  const HeaderForTable = () => {
    return (
      <div className="row px-8 pt-6">
        <div className="col-xl-12 mb-6 mb-xxl-6">
          <h3>{name}</h3>
        </div>
        <div className="col-xl-4 mb-6 mb-xxl-6">
          <div
            className="search-field w-100 w-sm-100 w-xs-100 position-relative"
            // style={{
            //   display: dataTypeDisplay !== "block" ? "block" : "none",
            // }}
          >
            <div className="static-img ">
              <img src={searchLogo} alt="icon" className="searchicon" />
            </div>
            <input
              type="text"
              className="form-control custom-input-field"
              vvalue={globalFilterValue}
              onChange={(e) => {
                setFilter((filters) => ({
                  ...filters,
                  global: {
                    ...filters["global"],
                    value: e?.target?.value.trim(),
                  },
                }));
                setGlobalFilterValue(e.target.value);
              }}
              placeholder="Search using Email address"
            />
          </div>
        </div>
      </div>
    );
  };

  const templateForPaginator = {
    layout:
      "RowsPerPageDropdown  FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport",
    CurrentPageReport: (options) => {
      return (
        <span className="mr-5">
          {options.currentPage} of {options.totalPages}
        </span>
      );
    },
    RowsPerPageDropdown: (options) => {
      const dropdownOptions = [
        { label: 10, value: 10 },
        { label: 20, value: 20 },
        { label: 30, value: 30 },
        { label: 40, value: 40 },
        { label: 50, value: 50 },
        // { label: 'All', value: options.totalRecords }
      ];
      return (
        <>
          {/* <div className='d-none d-md-none  d-sm-none d-xs-none  d-lg-flex ' style={{ position: 'absolute', left: '2px' }}> */}
          <div className="d-none d-md-none  d-sm-none d-xs-none  d-lg-flex ">
            <span className="Per-page">Per page</span>
            <Dropdown
              style={{ width: "auto" }}
              value={options.value}
              options={dropdownOptions}
              onChange={options.onChange}
              placeholder={"10"}
            />
            <span className="d-flex align-items-center">
              Displaying{" "}
              {(options.props.page + 1) * options.value > options.totalRecords
                ? options.totalRecords
                : (options.props.page + 1) * options.value}{" "}
              of {options.props.totalRecords}
            </span>
          </div>
        </>
      );
    },
  };
  const srNumberBody = (rowData, index) => {
    return <div style={{ width: "100%" }}>{index.rowIndex + 1}</div>;
  };

  // const creationDateBody = (rowData, index = null) => {
  //   if (rowData?.createdDate !== null && index !== null) {
  //     return (
  //       <>
  //         <div className="processStatusBlock ps-5">
  //           {rowData?.createdDate
  //             ? // ? getFormattedDate(rowData?.scheduledFor)
  //               // getFor(rowData?.date)
  //               getFormattedDateType1(rowData?.createdDate)
  //             : "NA"}
  //         </div>

  //         <div className="actionBlock">
  //           <div className="d-flex ps-3">
  //             {/* <Button icon="pi pi-bookmark" rounded severity="secondary" aria-label="Bookmark" /> */}

  //             <div
  //               className="template-btn"
  //               title="Review Mail"
  //               // onClick={(e) => {
  //               //   navigate(
  //               //     `/bank/campaign-management/review-email/${rowData.id}`,
  //               //   );
  //               // }}
  //             >
  //               <img
  //                 alt="icon"
  //                 style={{ paddingBottom: "3px" }}
  //                 src={exportimages.list_icon_eye}
  //               />
  //             </div>

  //             <div
  //               className="template-btn"
  //               title="Edit"
  //               // onClick={(e) => {
  //               //   e?.stopPropagation();
  //               //   navigate(
  //               //     `/bank/campaign-management/edit-campaign/${rowData.id}`,
  //               //   );
  //               // }}
  //             >
  //               <img
  //                 alt="icon"
  //                 style={{ paddingBottom: "3px" }}
  //                 src={exportimages.list_icon_edit_grey}
  //               />
  //             </div>

  //             <div
  //               className="template-btn"
  //               title="Delete"
  //               // onClick={(e) => {
  //               //   handleDeleteClick(rowData);
  //               //   setmodal(true);
  //               // }}
  //             >
  //               <img
  //                 alt="icon"
  //                 style={{ paddingBottom: "3px" }}
  //                 src={exportimages.list_icon_trash}
  //               />
  //             </div>
  //           </div>
  //         </div>
  //       </>
  //     );
  //   }
  // };
  return (
    <div
      className="px-10 pt-5 pb-10 contentWrapper dashboardContainer position-relative "
      id="alldata"
    >
      <div className="row g-0 g-xl-5 g-xxl-8">
        <div className="col-md-5 col-sm-12  ">
          <div className="custom-theme pageTitle">
            <h3 className="text-dark fs-1 fw-bolder lh-lg mb-1 dashboard-title">
              Consumers List Details
            </h3>
          </div>
          <div
            className="custom-theme pageSubTitle text-868687 "
            style={{ fontSize: "12px" }}
          >
            {/* <span>
              <Link
                id="graph_link_to_report"
                to={`/bank/health-analysis/report/${sessionStorage.getItem(
                  "healthAnalysisID",
                )}`}
                style={{ color: "#868687" }}
              >
                Dashboard
              </Link>
              {" > "}
            </span> */}
            <span>
              <Link
                id="graph_link_to_report"
                to={`/bank/campaign-management/email-campaigns`}
                style={{ color: "#868687" }}
              >
                Campaigns
              </Link>
              {" > "}
            </span>
            <span>
              <Link
                id="graph_link_to_report"
                to={`/bank/campaign-management/consumers-list`}
                style={{ color: "#868687" }}
              >
                Consumers List
              </Link>
              {" > "}
            </span>
            <span className="text-4b4b4b text-decoration-underline">
              Consumers List Details
            </span>
          </div>
        </div>
        <div
          className="col-md-7 col-sm-12 text-end mb-4"
          style={{ fontWeight: "normal!important" }}
        >
          <button
            type="button"
            id="kt_login_password_reset_form_cancel_button"
            onClick={() => {
              navigate(`/bank/campaign-management/consumers-list`);
            }}
            style={{ marginRight: "10px" }}
            className="mr-4 btn btn-outline-primary border-primary btn-bond mb-2 mt-2 px-3 py-3 px-xxl-3 btn-lg fs-5  w-20"
          >
            <img
              alt="back-arrow"
              src={exportimages?.backArrowNew}
              className="w-5px me-2 align-baseline img-fluid arrowImage"
            />
            <img
              alt="back-arrow"
              src={exportimages?.hoverBackArrowNew}
              className="w-5px me-2 align-baseline img-fluid arrowImageHover"
            />
            <span
              className="me-3 fs-5 fs-xxl-6 "
              style={{ fontWeight: "normal" }}
            >
              Back{" "}
            </span>
          </button>
        </div>
      </div>
      {/* <div
        className="row g-0 g-xl-5 g-xxl-8"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <div className="row g-0 g-xl-5 g-xxl-8">
          <div className="col-xl-12 mb-6 mb-xxl-6">
            <h4>Ready for dream house</h4>
            <span style={{ color: "#868687" }}>03 Jul ‘24, 10:27 AM</span>
          </div>
          <div className="col-md-12">
            <div className="mainContainer h-100 px-0">
              <div className="statistic">
                <div className="statistic-card">
                  <div className="statistic-badge">
                    <span>
                      <img
                        alt="reviews pending"
                        src={exportImages?.icon_users}
                      />
                    </span>
                    <div className="badge-text-content">
                      <p className="count">8</p>
                      <p>Targeted Consumers</p>
                    </div>
                  </div>
                </div>
                <div className="statistic-card">
                  <div className="statistic-badge">
                    <span>
                      <img
                        alt="reviews pending"
                        src={exportImages?.icon_ongoing_campaign}
                      />
                    </span>
                    <div className="badge-text-content">
                      <p className="count">8</p>
                      <p>Delivered</p>
                    </div>
                  </div>
                </div>
                <div className="statistic-card">
                  <div className="statistic-badge">
                    <span>
                      <img
                        alt="reviews pending"
                        src={exportImages?.icon_approved_campaign}
                      />
                    </span>
                    <div className="badge-text-content">
                      <p className="count">8</p>
                      <p>Email Viewed</p>
                    </div>
                  </div>
                </div>
                <div className="statistic-card">
                  <div className="statistic-badge">
                    <span>
                      <img
                        alt="reviews pending"
                        src={exportImages?.icon_ongoing_campaign}
                      />
                    </span>
                    <div className="badge-text-content">
                      <p className="count">8</p>
                      <p>CTA Clicks</p>
                    </div>
                  </div>
                </div>
                <div className="statistic-card">
                  <div className="statistic-badge">
                    <span>
                      <img
                        alt="reviews pending"
                        src={exportImages?.icon_mail_block}
                      />
                    </span>
                    <div className="badge-text-content">
                      <p className="count">8</p>
                      <p>Bounced Emails</p>
                    </div>
                  </div>
                </div>
                <div className="statistic-card">
                  <div className="statistic-badge">
                    <span>
                      <img
                        alt="reviews pending"
                        src={exportImages?.icon_mail_fail}
                      />
                    </span>
                    <div className="badge-text-content">
                      <p className="count">8</p>
                      <p>Unsubscribe</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div
        className="row g-0 g-xl-5 g-xxl-8"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <div className="row g-0 g-xl-5 g-xxl-8">
          <div className="col-md-12">
            <div className="mainContainer h-100 px-2">
              <DataTable
                value={filteredConsumerList}
                className="p-custom-datatable consumerDataTable sortDataTable h-100 "
                selectionMode="single"
                rows={10}
                paginator="true"
                reflow="true"
                paginatorTemplate={templateForPaginator}
                filters={filters}
                paginatorPosition="bottom"
                showcurrentpagereport="true"
                rowsPerPageOptions={[10, 20, 30, 40, 50]}
                currentPageReportTemplate="Displaying {last} of {totalRecords}"
                rowHover="true"
                scrollable="true"
                scrollHeight="flex"
                resizableColumns="true"
                responsiveLayout="scroll"
                size="large"
                globalFilterFields={["email"]}
                header={HeaderForTable}
                // onFilter={(e) => {
                //   setFilters(e.filters);
                // }}
                // onRowClick={(e) => setmodal(true)}
                emptyMessage={() => (
                  <h5 className="text-center pt-4 mt-1 mb-0 w-100">
                    <div className="col-md-12 mb-8">
                      <img src={exportimages.noDataFound} alt="no data" />
                    </div>
                    <div className="col-md-12">
                      <h5>There is no data to display at the moment.</h5>
                    </div>
                  </h5>
                )}
                // onSelectionChange={(e) => {
                //   setSelectedRowData(e.value);
                //   setmodal(true);
                // }}
              >
                <Column
                  className="ps-8"
                  header="Sr. No."
                  body={srNumberBody}
                  style={{ maxWidth: "15%", padding: "16px" }}
                />

                <Column
                  header="Email Address"
                  field="email"
                  style={{ maxWidth: "42%" }}
                  sortable
                />

                <Column
                  header="Name"
                  field="firstName"
                  // bodyClassName={"processedOn"}
                  body={(rowData) => rowData.firstName + " " + rowData.lastName}
                  headerClassName={"ps-2"}
                  style={{ maxWidth: "45%", padding: "16px" }}
                  sortable
                />
                {/* <Column
                  header="Email Status"
                  field="emailStatus"
                  body={(rowData) =>
                    rowData.emailStatus ? rowData.emailStatus : "Not Available"
                  }
                  headerClassName={"ps-4"}
                  style={{ maxWidth: "30%" }}
                  sortable
                /> */}
                {/* <Column
                  header="Date"
                  field="emailDate"
                  body={(rowData) =>
                    rowData.emailDate ? rowData.emailDate : "Not Available"
                  }
                  headerClassName={"ps-3"}
                  bodyClassName={"processStatusMainBlock px-1 py-1 pe-4"}
                  style={{ maxWidth: "30%", padding: "16px" }}
                  sortable
                /> */}
              </DataTable>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ConsumersDetailsPage;
