import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const financialHealthScoreAnalysisSlice = createApi({
  reducerPath: "analysis_financial_health_score",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
  }),
  tagTypes: ["FinancialHealthScoreSummary", "FinancialHealthScoreExpanded"],
  endpoints: (build) => ({
    getFinancialHealthScoreSummary: build.query({
      query: ({ orgId, headers, payload }) => ({
        url: `bank/${orgId}/fhs?card=''&view=summary&page=0&size=10`,
        method: "POST",
        headers: headers,
        body: payload,
      }),
      providesTags: ["FinancialHealthScoreSummary"],
    }),

    getFinancialHealthScoreExpanded: build.query({
      query: ({ orgId, headers, payload }) => ({
        url: `bank/${orgId}/fhs?card=''&view=expand&page=0&size=10`,
        method: "POST",
        headers: headers,
        body: payload,
      }),
      providesTags: ["FinancialHealthScoreExpanded"],
    }),
  }),
});

export const {
  useGetFinancialHealthScoreSummaryQuery,
  useGetFinancialHealthScoreExpandedQuery,
  useLazyGetFinancialHealthScoreSummaryQuery,
} = financialHealthScoreAnalysisSlice;
