import exportimages from "../assets/images/exportImages";

/* eslint-disable */
export const LANGUAGES = [
  { key: "id", display_value: "Bahasa Indonesia - Indonesian" },
  { key: "msa", display_value: "Bahasa Melayu - Malay" },
  { key: "ca", display_value: "CatalÃ  - Catalan" },
  { key: "cs", display_value: "ÄŒeÅ¡tina - Czech" },
  { key: "da", display_value: "Dansk - Danish" },
  { key: "de", display_value: "Deutsch - German" },
  { key: "en", display_value: "English US (Default)" },
  { key: "en-gb", display_value: "English UK - British English" },
  { key: "es", display_value: "EspaÃ±ol - Spanish" },
  { key: "eu", display_value: "Euskara - Basque (beta)" },
  { key: "fil", display_value: "Filipino" },
  { key: "fr", display_value: "FranÃ§ais - French" },
  { key: "ga", display_value: "Gaeilge - Irish (beta)" },
  { key: "gl", display_value: "Galego - Galician (beta)" },
  { key: "hr", display_value: "Hrvatski - Croatian" },
  { key: "it", display_value: "Italiano - Italian" },
  { key: "hu", display_value: "Magyar - Hungarian" },
  { key: "nl", display_value: "Nederlands - Dutch" },
  { key: "no", display_value: "Norsk - Norwegian" },
  { key: "pl", display_value: "Polski - Polish" },
  { key: "pt", display_value: "PortuguÃªs - Portuguese" },
  { key: "ro", display_value: "RomÃ¢nÄƒ - Romanian" },
  { key: "sk", display_value: "SlovenÄina - Slovak" },
  { key: "fi", display_value: "Suomi - Finnish" },
  { key: "sv", display_value: "Svenska - Swedish" },
  { key: "vi", display_value: "Tiáº¿ng Viá»‡t - Vietnamese" },
  { key: "tr", display_value: "TÃ¼rkÃ§e - Turkish" },
  { key: "el", display_value: "Î•Î»Î»Î·Î½Î¹ÎºÎ¬ - Greek" },
  { key: "bg", display_value: "Ð‘ÑŠÐ»Ð³Ð°Ñ€ÑÐºÐ¸ ÐµÐ·Ð¸Ðº - Bulgarian" },
  { key: "ru", display_value: "Ð ÑƒÑÑÐºÐ¸Ð¹ - Russian" },
  { key: "sr", display_value: "Ð¡Ñ€Ð¿ÑÐºÐ¸ - Serbian" },
  { key: "uk", display_value: "Ð£ÐºÑ€Ð°Ñ—Ð½ÑÑŒÐºÐ° Ð¼Ð¾Ð²Ð° - Ukrainian" },
  { key: "he", display_value: "×¢Ö´×‘Ö°×¨Ö´×™×ª - Hebrew" },
  { key: "ur", display_value: "Ø§Ø±Ø¯Ùˆ - Urdu (beta)" },
  { key: "ar", display_value: "Ø§Ù„Ø¹Ø±Ø¨ÙŠØ© - Arabic" },
  { key: "fa", display_value: "ÙØ§Ø±Ø³ÛŒ - Persian" },
  { key: "mr", display_value: "à¤®à¤°à¤¾à¤ à¥€ - Marathi" },
  { key: "hi", display_value: "à¤¹à¤¿à¤¨à¥à¤¦à¥€ - Hindi" },
  { key: "bn", display_value: "à¦¬à¦¾à¦‚à¦²à¦¾ - Bangla" },
  { key: "gu", display_value: "àª—à«àªœàª°àª¾àª¤à«€ - Gujarati" },
  { key: "ta", display_value: "à®¤à®®à®¿à®´à¯ - Tamil" },
  { key: "kn", display_value: "à²•à²¨à³à²¨à²¡ - Kannada" },
  { key: "th", display_value: "à¸ à¸²à¸©à¸²à¹„à¸—à¸¢ - Thai" },
  { key: "ko", display_value: "í•œêµ­ì–´ - Korean" },
  { key: "ja", display_value: "æ—¥æœ¬èªž - Japanese" },
  { key: "zh-cn", display_value: "ç®€ä½“ä¸­æ–‡ - Simplified Chinese" },
  { key: "zh-tw", display_value: "ç¹é«”ä¸­æ–‡ - Traditional Chinese" },
];

//RegEx Patterns
export const REGEX_PATTERN_UEL = () =>
  /^(http[s]?:\/\/){0,1}(www.){0,1}[a-zA-Z0-9.-]+.[a-zA-Z]{2,5}[.]{0,1}/;
export const REGEX_PATTERN_EMAIL = () =>
  /(\w*[a-zA-Z][\da-zA-Z\.-]*)@(\w*[a-zA-Z][\da-zA-Z\.-]*)\.([a-zA-Z\.]{2,9})$/;
export const REGEX_PATTERN_NUMBER = () =>
  /((\+*)((0[ -]*)*|((91 )*))((\d{12})+|(\d{10})+))|\d{5}([- ]*)\d{6}/;
export const REGEX_PATTERN_WHITESPACE = () =>
  /^([A-z0-9!@#$%^&*().,<>{}[\]<>?_=+\-|;:\'\"\/])*[^\s]\1*$/;
export const PASSWORD_POLICY = (value) => {
  let MIN_LENGTH = 8;
  let MAX_LENGTH = 16;
  // let REGEX_PATTERN = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\[\]{}\(\)?\-â€œ!@#%&\/,><\â€™:;|_~`])\S{8,99}$/
  let REGEX_PATTERN =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()\-=_+{}[\]:;"'<>,.?/~`|\\/])[a-zA-Z\d!@#$%^&*()\-=_+{}[\]:;"'<>,.?/~`|\\/]{8,}$/;

  let errorStatus = false;
  let errorMessage = "";
  if (value.length > MAX_LENGTH) {
    errorStatus = true;
    errorMessage = "Password should be in between 8 to 16 characters.";
  } else if (value.length === 0) {
    errorStatus = true;
    errorMessage = "Enter valid password.";
  } else if (!REGEX_PATTERN.test(value)) {
    errorStatus = true;
    errorMessage =
      "Password should be at least 8 characters long or maximum limit 16 characters with at least 1 uppercase letter, 1 special character and 1 number.";
  }
  return { errorStatus, errorMessage };
};

export const CONFIRM_PASSWORD = (password, value) => {
  let errorMessage = "";
  let errorStatus = false;
  if (!(password === value)) {
    errorStatus = true;
    errorMessage = "Passwords should match.";
  }
  return { errorStatus, errorMessage };
};

export const getUnit = (score, type) => {
  let unit;
  let newScore = score;
  if (score != 0) {
    if (score >= 1000 && score <= 999999) {
      score = score / 1000;
      unit = "K";
    }
    // millions
    else if (score >= 1000000 && score <= 999999999) {
      score = score / 1000000;
      unit = "M";
    }
    // billions
    else if (score >= 1000000000 && score <= 999999999999) {
      score = score / 1000000000;
      unit = "B";
    }
    // not in above
    else {
      // score;
    }
  }
  if (type === "score") {
    return Math.floor(score) === score ? score : score.toFixed(2);
  }

  return unit;
};

export const SUBSCRIPTION = {
  FREEMIUM_WITHOUT_FHS: "freemium_without_fhs",
  FREEMIUM_WITH_FHS: "freemium_with_fhs",
  PRO: "pro",
  MARKETING: "marketing",
};

export const OPPORTUNITIES = {
  credit_card: {
    name: "Credit Card",
    icon: exportimages.icon_credit_card,
    color: "#e5f1fb",
  },
  consumer_loan: {
    name: "Consumer Loan",
    icon: exportimages.icon_consumer_loan,
    color: "#c3e1ff",
  },
  mortgage: {
    name: "Mortgage",
    icon: exportimages.icon_mortgage,
    color: "#ffe2e9",
  },
  auto_loan: {
    name: "Auto Loan",
    icon: exportimages.icon_auto_loan,
    color: "#d2d9ff",
  },
  student_loan: {
    name: "Student Loan",
    icon: exportimages.icon_student_loan,
    color: "#ffcef7",
  },
  personal_loan: {
    name: "Personal Loan",
    icon: exportimages.icon_personal_loan,
    color: "#ffecc3",
  },
  business_loan: {
    name: "Business Loan",
    icon: exportimages.icon_business_loan,
    color: "#dcdaff",
  },
};

export const CONSUMERS = {
  primaryconsumer: {
    name: "Primary Consumers",
    icon: null,
    color: "#45A1FF",
    bgColor: "",
  },
  secondaryconsumer: {
    name: "Secondary Consumers",
    icon: null,
    color: "#687AE5",
    bgColor: "",
  },
  primary_consumer_with_dda: {
    name: "Primary DDA Accounts",
    icon: exportimages.icon_primary_consumer_with_dda,
    color: "#A1CBF5",
    bgColor: "#ffd9dd",
    zAxis: 75,
  },
  primary_consumer_without_dda: {
    name: "Primary Non DDA Accounts",
    icon: exportimages.icon_primary_consumer_without_dda,
    color: "#42A0FF",
    bgColor: "#ffc9f7",
    zAxis: 75,
  },
  secondary_consumer_with_dda: {
    name: "Secondary DDA Accounts",
    icon: exportimages.icon_secondary_consumer_with_dda,
    color: "#4D64EB",
    bgColor: "#edebff",
    zAxis: 175,
  },
  secondary_consumer_without_dda: {
    name: "Secondary Non DDA Accounts",
    icon: exportimages.icon_secondary_consumer_without_dda,
    color: "#7588F8",
    bgColor: "#d1e9fe",
    zAxis: 175,
  },
};

export const RESPONSE = {
  SUCCESS: {
    status_code: 200,
    status: "SUCCESS",
  },
};

const templates = JSON.parse(process.env.REACT_APP_CAMPAIGN_SAMPLE_TEMPLATES);

export const SAMPLE_TEMPLATES = [
  {
    sampleTemplateObj: [
      {
        id: templates[1],
        heading: "Ready to gain more!!",
        category: "Business Loan",
        src: "sample_template_one",
      },
      {
        id: templates[2],
        heading: "Escape High-Interest Rate",
        category: "Credit Card",
        src: "sample_template_two",
      },
      {
        id: templates[0],
        heading: "Binge your favourite Netflix for free.",
        category: "Savings Account",
        src: "sample_template_three",
      },
    ],
  },
];
