/* eslint-disable */
import debounce from "lodash.debounce";
import { REGEX_PATTERN_EMAIL, REGEX_PATTERN_WHITESPACE } from "./global";
import lang from "../assets/i18n/en.json";

const equalsIgnoreCase = (string1, string2) => {
  if (
    undefined === string1 ||
    null === string1 ||
    undefined === string2 ||
    null === string2
  ) {
    return false;
  }
  if (
    string1.toString().trim().toUpperCase() ===
    string2.toString().trim().toUpperCase()
  ) {
    return true;
  }
  return false;
};
export const clearAndLogout = debounce((name) => sessionTimeOut(), 1000);
const sessionTimeOut = () => {
  window.alert("Your session has expired. Please sign in again.");
  sessionStorage.clear();
  let currentURL = window.location.href.split("/");
  if (currentURL[3] === "admin") {
    window.location = "/admin-signin";
  } else {
    window.location = "/signin";
  }
};
export function addCommas(number) {
  const numberString = number.toFixed(2); // Ensure 2 decimal places

  const parts = numberString.split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  // Remove trailing zeros and decimal point if unnecessary
  if (parts[1] === "00") {
    return parts[0];
  } else {
    return parts.join(".");
  }
}
const matchSpecialCharacters = (string) => {
  let pattern = /^[\w\s&.\-]*$/;

  if (string.match(pattern)) {
    return false;
  }

  return true;
};
const convertLocalDateToUTCDate = (date) => {
  let utcDateTime = "";

  utcDateTime = new Date(date).toISOString();

  return utcDateTime;
};
// export const validateEmail = (email) => {
//   return !email.match(
//     /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
//   );
// };
export const getTimestamp = () => {
  return Date.now();
};
export function convertToTitleCase(input) {
  // Split the input string into words
  const words = input.split(" ");

  // Capitalize the first letter of each word
  const titleCaseWords = words.map((word) => {
    return word.charAt(0).toUpperCase() + word.slice(1)?.toLowerCase();
  });

  // Join the words back together
  const titleCaseString = titleCaseWords.join(" ");

  return titleCaseString;
}
export const validateEmail = (email, errors, setError) => {
  /** VALIDATING EMAIL  */
  let checkWorkEmail = email.toLowerCase();
  const regex = REGEX_PATTERN_EMAIL();
  const whiteSpace = REGEX_PATTERN_WHITESPACE();
  let workMail = !(
    checkWorkEmail.includes("gmail") ||
    checkWorkEmail.includes("yahoo") ||
    checkWorkEmail.includes("outlook") ||
    checkWorkEmail.includes("hotmail") ||
    checkWorkEmail.includes("aol")
  );
  if (
    regex.test(email) &&
    email.match(whiteSpace) &&
    workMail &&
    email.length < 500
  ) {
    setError((errors) => ({
      ...errors,
      email: false,
      emailError: "",
    }));
  } else if (email.length > 500) {
    setError((errors) => ({
      ...errors,
      email: true,
      emailError: lang?.PAGE?.AUTH?.SIGNUP?.EMAIL?.MAX_LENGTH,
    }));
  } else {
    setError((errors) => ({
      ...errors,
      email: true,
      emailError: "Must enter a valid work email address.",
    }));
  }
};

export function stringToHex(str) {
  let hex = "";
  for (let i = 0; i < str.length; i++) {
    let charCode = str.charCodeAt(i).toString(16);
    hex += ("00" + charCode).slice(-2);
  }
  return hex;
}
export function hexToString(hex) {
  let str = "";
  for (let i = 0; i < hex.length; i += 2) {
    let charCode = parseInt(hex.substr(i, 2), 16);
    str += String.fromCharCode(charCode);
  }
  return str;
}

export const validateDateTime = (value, errors, setError) => {
  /** VALIDATING Date and time  */
  const selectedDateTime = new Date(value).getTime();
  const currentDateTime = new Date().getTime();
  if (selectedDateTime < currentDateTime) {
    setError((errors) => ({
      ...errors,
      scheduledFor: true,
      scheduledForError:
        "Please select a time that is ahead of the current time.",
    }));
  } else {
    setError((errors) => ({
      ...errors,
      scheduledFor: false,
      scheduledForError: "",
    }));
  }
};

export const validateEmailTemplate = (data) => {
  const {
    design: { body },
  } = data;
  const { rows } = body;
  let isEmpty = true;
  label: for (let i = 0; i < rows.length; i++) {
    const { columns } = rows[i];
    for (let j = 0; j < columns.length; j++) {
      const { contents } = columns[j];
      if (contents.length) {
        isEmpty = false;
        break label;
      }
    }
  }

  return isEmpty;
};

export function AmountsInThousand(number) {
  if (number != 0) {
    // number = number / 1000;
    const numberString = number.toFixed(2); // Ensure 2 decimal places
    const parts = numberString.split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts[0];
  } else {
    return number;
  }
}

export function setCookie(cname, cvalue, exdays) {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  let expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

export function getCookie(cname) {
  let name = cname + "=";
  let ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export function deleteAllCookies() {
  var cookies = document.cookie.split(";");
  for (var i = 0; i < cookies.length; i++) {
    var cookie = cookies[i];
    var eqPos = cookie.indexOf("=");
    var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie =
      name +
      "=;" +
      "expires=Thu, 01-Jan-1970 00:00:01 GMT;" +
      "path=" +
      "/;" +
      "domain=" +
      window.location.host +
      ";" +
      "secure=;";
  }
}

export const domainName = (url) =>
  url.replace(/^https?:\/\//, "").replace(/^www\./, "");

export const fetchCurrentCachedState = (cachedQueries) => {
  let result = {};
  Object.values(cachedQueries).forEach((item) => {
    result = {
      ...result,
      ...{ [item?.endpointName]: item?.data?.data },
    };
  });
  return result;
};
