import "./InstitutionList.scss";
import React, { useEffect, useRef, useState } from "react";
import { AutoComplete } from "primereact/autocomplete";
// import { CountryService } from "./service/CountryService";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "primereact/button";
import { useLocation, useNavigate } from "react-router";
import {
  setIsFhsAvailable,
  setIsMarketing,
  setIsPro,
} from "../../../Redux/subscriptionSlice";
import {
  financialHealthScoreAnalysisSlice,
  useLazyGetFinancialHealthScoreSummaryQuery,
} from "../../../rtk-query/features/dashboard/financialHealthScore/financialHealthScoreAnalysisSlice";
import {
  setHealthAnalysisID,
  setOrgId,
  setOrgKey,
  setOrgName,
  setOrgShortName,
  setRefreshedFI,
  setSelectedFI,
} from "../../../Redux/orgDetailsSlice";
import { setPerformaLoading } from "../../../Redux/loaderSlice";
import GetFromServer from "../../../utils/GetFromServer";
import { SUBSCRIPTION } from "../../../utils/global";

const InstitutionList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const autoCompleteRef = useRef();
  const [getLazyFHS] = useLazyGetFinancialHealthScoreSummaryQuery();
  const orgReducer = useSelector((state) => state.orgReducer);
  const authReducer = useSelector((state) => state.authReducer);
  const [institutions, setinstitutions] = useState([]);
  const [searchTyping, setSearchTyping] = useState(null);
  const [selectedInstitution, setSelectedInstitution] = useState({});
  const [onSelectedInstitution, setOnSelectedInstitution] = useState(false);
  const [filteredinstitutions, setFilteredinstitutions] = useState([]);

  const search = (event) => {
    setTimeout(() => {
      let filteredData;
      if (!event.query.trim().length) {
        filteredData = [...institutions];
      } else {
        filteredData = institutions.filter((institute) => {
          return institute.name
            .toLowerCase()
            .includes(event.query.toLowerCase());
        });
      }

      // TODO: Remove bank name comparison and switch to id key comparison (Recommended no to use toLowerCase())
      const uniqueNames = filteredData.reduce((acc, currentValue) => {
        if (
          !acc.some(
            (obj) =>
              obj.compare_name.toLowerCase() ===
              currentValue.compare_name.toLowerCase(),
          )
        ) {
          acc.push(currentValue);
        }
        return acc;
      }, []);
      setFilteredinstitutions(uniqueNames);
    }, 250);
  };

  const itemTemplate = (item) => {
    const selected = item?.code === selectedInstitution?.code;
    return (
      <div
        className={`institution-list flex align-items-center ${
          selected ? "selected" : ""
        } ${item?.isRevokeAccess === false ? "disabled" : ""}`}
      >
        <div>{item.name}</div>
      </div>
    );
  };

  useEffect(() => {
    const nonRegisteredFiList = orgReducer?.allFiList?.filter(
      (item) => item?.isRegister === false,
    );

    const clubFiList = [
      ...orgReducer?.registeredFiList,
      ...nonRegisteredFiList,
    ];

    const sortedClubFiList = clubFiList.sort((a, b) =>
      a.compare_name.localeCompare(b.compare_name),
    );

    setinstitutions(sortedClubFiList);
  }, [orgReducer?.registeredFiList, orgReducer?.allFiList]);

  useEffect(() => {
    setSelectedInstitution(orgReducer?.selectedFI);
    setOnSelectedInstitution(true);
  }, []);

  const onInstituteSelection = async (e) => {
    dispatch(setIsPro(false));
    dispatch(setIsMarketing(false));

    if (e?.value?.tempIsPro === null) {
      dispatch(
        financialHealthScoreAnalysisSlice.util.invalidateTags([
          "FinancialHealthScoreSummary",
        ]),
      );
      const orgId = e.value.orgKey;
      const headers = {
        Authorization: `${authReducer?.userData?.tokenType?.toLowerCase()} ${
          authReducer?.userData?.token
        }`,
      };
      const payload = {
        filter: {},
        requid: "",
        gte: "",
        lte: "",
        duration: "",
      };

      const fhsResponse = await getLazyFHS({ orgId, headers, payload });
      dispatch(
        setSelectedFI({
          ...e?.value,
          isBelogToCurrentReseller: true,
        }),
      );
      dispatch(setRefreshedFI(true));
      dispatch(setOrgKey(e?.value?.orgKey));
      dispatch(setOrgId(e?.value?.orguid));
      dispatch(setOrgName(e?.value?.org_name));
      setSelectedInstitution(e.value);
      dispatch(setOrgShortName(e?.value?.compare_name));
      if (fhsResponse?.data?.status_code === 200) {
        dispatch(setHealthAnalysisID(null));
        dispatch(setIsFhsAvailable(true));
        dispatch(setIsPro(false));
        dispatch(setIsMarketing(false));
        setOnSelectedInstitution(true);
        navigate("/bank/health-analysis/report/no-analysis-id", {
          state: {
            orguid: e.value.orguid,
          },
        });
        return null;
      } else if (fhsResponse?.error?.status === 404) {
        dispatch(setHealthAnalysisID(null));
        dispatch(setIsFhsAvailable(false));
        dispatch(setIsPro(false));
        dispatch(setIsMarketing(false));
        setOnSelectedInstitution(true);
        navigate("/user/nofhsdashboard");
        return null;
      } else {
        dispatch(setHealthAnalysisID(null));
        dispatch(setIsFhsAvailable(false));
        dispatch(setIsPro(false));
        dispatch(setIsMarketing(false));
        setOnSelectedInstitution(true);
        navigate("/user/nofhsdashboard");
        return null;
      }
    } else {
      if (e?.value?.tempIsPro === SUBSCRIPTION.FREEMIUM_WITHOUT_FHS) {
        dispatch(setHealthAnalysisID(null));
        dispatch(setSelectedFI(e?.value));
        dispatch(setRefreshedFI(true));
        dispatch(setOrgKey(e?.value?.orgKey));
        dispatch(setOrgId(e?.value?.orguid));
        dispatch(setOrgName(e?.value?.org_name));
        setSelectedInstitution(e.value);
        dispatch(setIsFhsAvailable(false));
        dispatch(setIsPro(false));
        dispatch(setIsMarketing(false));
        setOnSelectedInstitution(true);
        navigate("/user/nofhsdashboard");
        return null;
      } else if (e?.value?.tempIsPro === SUBSCRIPTION.FREEMIUM_WITH_FHS) {
        if (orgReducer?.selectedFI?.tempIsPro === e?.value?.tempIsPro) {
          dispatch(setPerformaLoading(true));
        }
        dispatch(setHealthAnalysisID(null));
        dispatch(setSelectedFI(e?.value));
        dispatch(setRefreshedFI(true));
        dispatch(setOrgKey(e?.value?.orgKey));
        dispatch(setOrgId(e?.value?.orguid));
        dispatch(setOrgName(e?.value?.org_name));
        setSelectedInstitution(e.value);
        dispatch(setOrgShortName(e?.value?.compare_name));
        dispatch(setIsFhsAvailable(true));
        dispatch(setIsPro(false));
        dispatch(setIsMarketing(false));
        setOnSelectedInstitution(true);
        navigate("/bank/health-analysis/report/no-analysis-id", {
          state: {
            orguid: e.value.orguid,
          },
        });
        return null;
      } else if (e?.value?.tempIsPro === SUBSCRIPTION.PRO) {
        dispatch(setIsFhsAvailable(true));
        dispatch(setIsPro(true));
        dispatch(setIsMarketing(false));
      } else if (e?.value?.tempIsPro === SUBSCRIPTION.MARKETING) {
        dispatch(setIsFhsAvailable(true));
        dispatch(setIsPro(true));
        dispatch(setIsMarketing(true));
      } else {
        dispatch(setHealthAnalysisID(null));
        dispatch(setSelectedFI(e?.value));
        dispatch(setRefreshedFI(true));
        dispatch(setOrgKey(e?.value?.orgKey));
        dispatch(setOrgId(e?.value?.orguid));
        dispatch(setOrgName(e?.value?.org_name));
        setSelectedInstitution(e.value);
        dispatch(setIsFhsAvailable(false));
        dispatch(setIsPro(false));
        dispatch(setIsMarketing(false));
        setOnSelectedInstitution(true);
        navigate("/user/nofhsdashboard");
        return null;
      }

      let response1 = await GetFromServer(
        "/bank/healthanalysis",
        {},
        {},
        { orguid: e.value.orguid, type: "none" },
      );

      let successfullUid = response1.data.data.find(
        (item) => item?.status?.toLowerCase() === "complete",
      );

      if (successfullUid) {
        dispatch(setHealthAnalysisID(successfullUid));
        dispatch(setSelectedFI(e?.value));
        dispatch(setRefreshedFI(true));
        dispatch(setOrgKey(e?.value?.orgKey));
        dispatch(setOrgId(e?.value?.orguid));
        dispatch(setOrgName(e?.value?.org_name));
        setSelectedInstitution(e.value);
        dispatch(setOrgShortName(e?.value?.compare_name));
        setOnSelectedInstitution(true);
        navigate(`/bank/health-analysis/report/${successfullUid.uid}`, {
          state: {
            orguid: e.value.orguid,
          },
        });
        return null;
      } else {
        dispatch(setHealthAnalysisID(null));
        dispatch(setSelectedFI(e?.value));
        dispatch(setRefreshedFI(true));
        dispatch(setOrgKey(e?.value?.orgKey));
        dispatch(setOrgId(e?.value?.orguid));
        dispatch(setOrgName(e?.value?.org_name));
        setSelectedInstitution(e.value);
        dispatch(setOrgShortName(e?.value?.compare_name));
        navigate("/bank/health-analysis/report/no-analysis-id", {
          state: {
            orguid: e.value.orguid,
          },
        });
        return null;
      }
    }
  };

  return (
    <>
      <div className="institution-list card flex justify-content-center">
        <div className="p-inputgroup flex-1">
          {onSelectedInstitution && (
            <Button
              icon="pi pi-search"
              // onClick={() => {
              //   setSearchTyping("");
              //   setOnSelectedInstitution(false);
              //   autoCompleteRef.current.getInput().focus();
              // }}
              className="search"
              onFocus={() => {
                setSearchTyping("");
                setOnSelectedInstitution(false);
                autoCompleteRef.current.getInput().focus();
              }}
            />
          )}

          <AutoComplete
            field="name"
            ref={autoCompleteRef}
            value={onSelectedInstitution ? selectedInstitution : searchTyping}
            suggestions={filteredinstitutions}
            virtualScrollerOptions={{ itemSize: 38 }}
            placeholder="Search Institution"
            completeMethod={search}
            onChange={(e) => {
              if (
                e.originalEvent.type === "click" ||
                e.originalEvent.keyCode === 13
              ) {
                if (e.value.isRevokeAccess !== false) {
                  setSearchTyping(e?.value?.compare_name);
                  setOnSelectedInstitution(false);
                  dispatch(setRefreshedFI(false));
                  onInstituteSelection(e);
                }
              } else {
                setSearchTyping(e.value);
                setOnSelectedInstitution(false);
              }
            }}
            onFocus={(e) => {
              setSearchTyping(selectedInstitution?.compare_name);
              setOnSelectedInstitution(false);
              e.target.select();
              // const select = document.querySelector(".p-autocomplete-dropdown");
              // select.click();
            }}
            onBlur={() => {
              setSearchTyping("");
              setOnSelectedInstitution(true);
            }}
            itemTemplate={itemTemplate}
            // dropdown
            // onDropdownClick={() => {
            //   setSearchTyping(selectedInstitution?.compare_name);
            //   setOnSelectedInstitution(false);
            // }}
          />
        </div>
      </div>
    </>
  );
};

export default InstitutionList;
