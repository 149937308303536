import "./NoBankDashboard.scss";
// import { Card } from "primereact/card";
// import Countdown, { zeroPad } from "react-countdown";
// import Button from "react-bootstrap/Button";
// import asset from "./../../../assets/images/in-progress/Asset_4_1.svg";
import constellation from "../../assets/images/in-progress/big_constellation.svg";
import ellipse_2511 from "../../assets/images/in-progress/background.svg";
import group_3 from "../../assets/images/in-progress/reseller_bank_img.svg";
// import { useEffect, useState } from "react";
// import { useSelector } from "react-redux/es/hooks/useSelector";
// import { useNavigate } from "react-router";

export const NoBankDashboard = () => {
  return (
    <>
      <div className="noAnalysisBlock h-100">
        <div className=" text-center w-100  h-100 d-flex flex-column align-items-center justify-content-between">
          <div
            className="mb-2 mb-xxl-4 m-9 mt-5 "
            style={{ alignSelf: "start" }}
          >
            <div className="custom-theme pageTitle float-start w-100" id="">
              <h3 className="text-dark fs-1 fw-bolder lh-lg mb-0">
                {/* {(orgReducer?.orgShortName?.split("-"))[0]} */}
              </h3>
            </div>
          </div>
          <div>
            <div className="col-md-12">
              <p
                className="blockTitle flex-grow-1 fs-1  mb-8"
                style={{ fontWeight: 900 }}
              >
                Welcome to the Autopilot!
              </p>
              <div className="col-md-12 mb-8">
                <img
                  alt="logo"
                  src={group_3}
                  className="group_3"
                  style={{ width: "28vw" }}
                />
              </div>
            </div>
            <div className="col-md-12">
              <p className="mb-0 d-flex justify-content-center align-items-center fs-5 fs-xxl-6 ">
                <span style={{ width: "29%" }}>
                  Please take a moment to view the selection of financial
                  institutions listed in the Institution menu option above. Once
                  you've made your choice, you'll unlock a range of
                  functionalities, including the ability to access the Financial
                  Health Score specific to your chosen institution and extend
                  invitations to other users as needed.
                </span>
              </p>
            </div>
          </div>
          <div />
        </div>
      </div>
      {/* <div className="inprogress pt-10">
        <div className="container text-start">
          <div className="row">
            <div className="col-md-6">
              <div className="row g-0 g-xl-5 g-xxl-8">
                <div className="col-xl-12 mb-2 mb-xxl-4">
                  <div className="custom-theme float-start w-100" id="">
                    <h3
                      className="fs-1 fw-bolder lh-lg mb-0"
                      style={{ color: "#fff" }}
                    >
                   
                    </h3>
                  </div>
                </div>
              </div>
              <div style={{ marginTop: "30%" }}>
                <p
                  className="blockTitle flex-grow-1 fw-bolder fs-3 mt-1 mb-5"
                  style={{ color: "#fff" }}
                >
                  Welcome to the Autopilot!
                </p>
                <div
                  className="mb-2 light  mt-1  fs-7 mb-7"
                  style={{ lineHeight: "1.35" }}
                >
                  Please take a moment to view the selection of financial
                  institutions listed in the Institution menu option above. Once
                  you've made your choice, you'll unlock a range of
                  functionalities, including the ability to access the Financial
                  Health Score specific to your chosen institution and extend
                  invitations to other users as needed.
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <img
                alt="logo"
                src={group_3}
                className="group_3"
                style={{ width: "34vw" }}
              />
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default NoBankDashboard;
