import axios from "axios";
import { globalError } from "./global-error-handler";
import { store } from "../app/store";
import { LogOut } from "../Redux/authSlice";
import { toast } from "react-toastify";
import { toastResponse } from "../helper components/Toast/Toast";
import errormessages from "../assets/i18n/en.json";
import { useState } from "react";

export const putToServer = async (
  url,
  data = {},
  headers = {},
  params = {},
) => {
  // INITIALIZATION
  const state = store.getState();
  // ACCESING REDUCER IN NORMAL FUNCTION
  const authReducer = state.authReducer;
  axios.defaults.baseURL = process.env.REACT_APP_API_URL;
  // ADDING CORS
  if (!url.includes("mapping")) {
    data = { ...data, mode: "cors" };
  } else {
  }
  // SETTING DEFAULT HEADERS

  axios.defaults.headers.common["Authorization"] =
    authReducer.isLogin === false
      ? headers
      : `${authReducer.userData.tokenType.toLowerCase()} ${
          authReducer?.userData?.token
        }`;
  try {
    if (navigator.onLine) {
      const response = await axios.put(url, data, headers, params);
      return response;
    } else {
      toast.error(
        toastResponse(
          "error",
          "There seems to be issues with internet connectivity. Please check your connection.",
        ),
      );
    }
  } catch (error) {
    if (
      error.response.status === 401 &&
      url !== "/auth/logout?lang=en_us&source=website"
    ) {
      // LOGING OUT IF INVALID TOKEN
      store.dispatch(LogOut());
    } else {
      // GETING ERRORS FROM GLOBAL ERROR HANDLER
      let filteredError = [];
      if (Array.isArray(error?.response?.data?.data)) {
        filteredError = error?.response?.data?.data?.filter(
          (item) => item.status !== "VALID_FILE",
        );
      }
      filteredError = {
        message: filteredError[0]?.status,
      };
      // console.log((error.response?.data?.data).reduce((acc,item)=>acc.data=item.status!=='VALID_FILE'?item.status:acc,{data:""}))
      error.data =
        error.response.status === 401
          ? error.response
          : Array.isArray(error.response?.data.data)
            ? filteredError
            : error.response.data;
      const errorDetails = globalError(
        error?.response
          ? error?.response
          : {
              data: {
                status: "ERROR",
                error: error?.code,
              },
            },
      );

      if (error.response.status === 401 && error.config.url.includes("logout")) {
        // toast.error(toastResponse("error", errormessages.ERROR.CODE[401]));
      } else {
        toast.error(toastResponse("error", errorDetails.message));
      }
      return Promise.reject({ ...error.response, data: { ...errorDetails } });
    }
  }
};
