import "./ListOptions.scss";
import { NavLink } from "react-router-dom";
const ListOptions = ({ options, onClick }) => {
  return (
    <ul className="list-options">
      {options.map((item, index) => {
        return (
          <li key={index} onClick={() => onClick(item)}>
            {item.icon && (
              <img src={item.icon} className="peyeicon" alt={item.label} />
            )}
            <NavLink to={item.navUrl}>{item.label}</NavLink>
            {/* {item.label} */}
          </li>
        );
      })}
    </ul>
  );
};

export default ListOptions;
