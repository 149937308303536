import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const secondaryConsumerAnalysisSlice = createApi({
  reducerPath: "analysis_secondary_consumer",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
  }),
  tagTypes: ["secondary_consumer"],
  endpoints: (build) => ({
    getSecondaryConsumerSummary: build.query({
      query: ({ orgId, headers, payload }) => ({
        url: `bank/${orgId}/dashboard?card=primary_consumer&view=summary&page=0&size=10`,
        method: "POST",
        headers: headers,
        body: payload,
      }),
    }),

    getSecondaryConsumerExpended: build.query({
      query: ({ orgId, headers, payload }) => ({
        url: `bank/${orgId}/dashboard?card=primary_consumer&view=expand&page=0&size=10`,
        method: "POST",
        headers: headers,
        body: payload,
      }),
    }),
  }),
});

export const {
  useGetSecondaryConsumerSummaryQuery,
  useGetSecondaryConsumerExpendedQuery,
} = secondaryConsumerAnalysisSlice;
