import "./NoFHSReseller.scss";
import group_3 from "../../assets/images/in-progress/reseller_bank_img.svg";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { setOrgShortName, setRefreshedFI } from "../../Redux/orgDetailsSlice";
import { SUBSCRIPTION } from "../../utils/global";

export const NoFHSReseller = () => {
  const dispatch = useDispatch();
  const orgReducer = useSelector((state) => state.orgReducer);
  useEffect(() => {
    if (
      orgReducer?.selectedFI?.tempIsPro === SUBSCRIPTION.FREEMIUM_WITHOUT_FHS ||
      orgReducer?.selectedFI?.tempIsPro === null
    ) {
      dispatch(setOrgShortName(orgReducer?.selectedFI?.compare_name));
      dispatch(setRefreshedFI(true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgReducer?.selectedFI]);
  return (
    <>
      {/* <div className="inprogress pt-10">
        <div className="container text-start">
          <div className="row">
            <div className="col-md-6">
              <div className="row g-0 g-xl-5 g-xxl-8">
                <div className="col-xl-12 mb-2 mb-xxl-4">
                  <div className="custom-theme float-start w-100" id="">
                    <h3
                      className="fs-1 fw-bolder lh-lg mb-0"
                      style={{ color: "#fff" }}
                    >
                      {orgReducer?.orgShortName?.split("-")[0]}
                    </h3>
                  </div>
                </div>

              </div>
              <div style={{ marginTop: "30%" }}>
                <p
                  className="blockTitle flex-grow-1 fw-bolder fs-3 mt-1 mb-5"
                  style={{ color: "#fff" }}
                >
                  Welcome to the Autopilot!
                </p>
                <div
                  className="mb-2 light  mt-1  fs-7 mb-7"
                  style={{ lineHeight: "1.35" }}
                >
                  Regrettably, the financial institution you've chosen lacks
                  adequate data at the moment to generate the Financial Health
                  Score. Rest assured, our team is diligently working to analyze
                  and compute precise scores for all institutions. Please
                  revisit later to access the scores for this particular
                  financial institution.
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <img
                alt="logo"
                src={group_3}
                className="group_3"
                style={{ width: "34vw" }}
              />
            </div>
          </div>
        </div>
      </div> */}

      <div className="noAnalysisBlock h-100">
        <div className=" text-center w-100  h-100 d-flex flex-column align-items-center justify-content-between">
          <div
            className="mb-2 mb-xxl-4 m-9 mt-5 "
            style={{ alignSelf: "start" }}
          >
            <div className="custom-theme pageTitle float-start w-100" id="">
              <h3 className="text-dark fs-1 fw-bolder lh-lg mb-0">
                {/* {orgReducer?.orgShortName}{" "} */}
              </h3>
            </div>
          </div>
          <div>
            <div className="col-md-12">
              <p
                className="blockTitle flex-grow-1 fs-1  mb-8"
                style={{ fontWeight: 900 }}
              >
                Welcome to the Autopilot!
              </p>
              <div className="col-md-12 mb-8">
                <img
                  alt="logo"
                  src={group_3}
                  className="group_3"
                  style={{ width: "28vw" }}
                />
              </div>
            </div>
            <div className="col-md-12">
              <p className="mb-0 d-flex justify-content-center align-items-center fs-5 fs-xxl-6 ">
                <span style={{ width: "29%" }}>
                  Regrettably, the financial institution you've chosen lacks
                  adequate data at the moment to generate the Financial Health
                  Score. Rest assured, our team is diligently working to analyze
                  and compute precise scores for all institutions. Please
                  revisit later to access the scores for this particular
                  financial institution.
                </span>
              </p>
            </div>
          </div>
          <div />
        </div>
      </div>
    </>
  );
};

export default NoFHSReseller;
