import { useState, createContext } from "react";
import { deleteAllCookies } from "./Utils";

export const ExitContext = createContext();

export const initBeforeUnLoad = (showExitPrompt) => {
  window.onbeforeunload = (event) => {
    window.history.pushState({}, "", "/user/clearsession");
    if (showExitPrompt) {
      const e = event || window.event;

      e.preventDefault();
      if (e) {
        e.returnValue = "";
      }
      return "";
    }
  };
  window.unload = function (event) {
    localStorage.clear();
    sessionStorage.clear();
  };
};

// Hook
export default function useExitPrompt(bool) {
  const [showExitPrompt, setShowExitPrompt] = useState(false);

  window.onload = function () {
    initBeforeUnLoad(showExitPrompt);
  };

  return [showExitPrompt, setShowExitPrompt];
}
