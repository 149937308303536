import { useEffect, useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import GetFromServer from "../../../utils/GetFromServer";
import { useSelector } from "react-redux";

const RecursiveCall = () => {
  const authReducer = useSelector((state) => state.authReducer);
  const orgReducer = useSelector((state) => state.orgReducer);
  const [state, setState] = useState("Active");
  const [count, setCount] = useState(0);
  const [remaining, setRemaining] = useState(0);
  const [idleTime, setIdleTime] = useState(0);
  const [totalActiveTime, setTotalActiveTime] = useState(0);

  const onIdle = () => {
    setState("Idle");
  };

  const onActive = () => {
    setState("Active");
  };

  const onAction = () => {
    setCount(count + 1);
  };

  const { getTotalActiveTime, getRemainingTime, getIdleTime } = useIdleTimer({
    onIdle,
    onActive,
    onAction,
    timeout: 60_000,
    throttle: 500,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000));
      setIdleTime(Math.ceil(getIdleTime() / 1000));
      setTotalActiveTime(Math.ceil(getTotalActiveTime() / 1000));
    }, 500);

    return () => {
      clearInterval(interval);
    };
  });

  useEffect(() => {
    if (
      state === "Idle" &&
      totalActiveTime >= Number(authReducer?.userData?.expiresIn)
    ) {
      window.confirm("Your session is expired and the system will logout!");
      (async () => {
        await GetFromServer("/role?", {}, {}, { orguid: orgReducer.orguid });
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, idleTime]);
  return (
    <>
      {/* Testing session timeout */}
      <h1 className="mx-20" style={{ display: "none" }}>
        {
          <>
            <p>Current State: {state}</p>
            <p>Action Events: {count}</p>
            <p>{remaining} seconds remaining</p>
            <p>getIdleTime: {idleTime}</p>
            <p>totalActiveTime: {totalActiveTime}</p>
          </>
        }
      </h1>
    </>
  );
};

export default RecursiveCall;
