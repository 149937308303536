  // Function to convert category name to a more readable format
  export const formatCategoryName = (categoryName) => {
    // Split the category name by underscores and capitalize each word
    if (categoryName) {
      const words = categoryName
        .split("_")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1));
      // Join the words back together with a space
      return words.join(" ");
    } else {
      return categoryName;
    }
  };
  export function capitalizeFirstLetterOfEachWord(sentence) {
    return sentence.replace(/\b\w/g, (char) => char.toUpperCase());
}
